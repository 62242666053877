import {ReactNode} from 'react'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import i18next from 'i18next'
import {RootState} from './store'
import {getDisplayHash, sortByBlockNum} from '../utils/functions'
import {
    checkResponse, setModalAddTicketsToShowcaseV2,
    setModalCreateShowcaseV2, setModalEditShowcase,
    setModalError,
    setModalSendTransactions, setRedirectPath
} from './appSlice'
import {_AssetType, API_URL, CHAINS, NULL_ADDRESS} from '../utils/constants'
import Erc20Abi from '../utils/abi/erc20.json'
import {setTickets} from './ticketsSlice'
import {
    ITransaction,
    IPrice,
    IAssetItem,
    IItemOnSale,
    IDisplay,
    ICheckedTicket,
    SliceResponse,
    DBEventWithOrganizer,
    ISendTransaction,
    DBShowcase,
    IDiscounts,
    IShowcase, IBatchItemsParams, IEditAssetItemPriceParams, IShowcaseV2Params, TShowcaseType, DBCalendarEventType
} from './types'
import {getFromStorage} from './storage'

interface ShowcaseV2State {
    addedShowcaseName: string | null
    discounts: IDiscounts
    selectedTickets: ICheckedTicket[]
    showcase: IDisplay | null
    showcaseEvents: DBEventWithOrganizer[] | null
    showcaseName: string | null
    showcases: IShowcase[] | null
}

const initialState: ShowcaseV2State = {
    addedShowcaseName: null,
    discounts: {},
    selectedTickets: [],
    showcase: null,
    showcaseEvents: null,
    showcaseName: null,
    showcases: null,
}

export const addBatchItemsToShowcase = createAsyncThunk(
    'showcaseV2/addBatchItemsToShowcase',
    async (params: IBatchItemsParams, {dispatch, getState}): Promise<boolean> => {
        const {prices} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {showcaseName, selectedTickets} = state.showcaseV2
        const display = getDisplay(state)

        if (!currentNetwork || !web3 || !walletAddress || !showcaseName || selectedTickets.length === 0 || !display) {
            return false
        }

        for (let item of prices) {
            if (item.payWith !== NULL_ADDRESS) {
                try {
                    const erc20Contract = new web3.eth.Contract(Erc20Abi as AbiItem[], item.payWith)
                    await erc20Contract.methods.decimals().call()
                    await erc20Contract.methods.symbol().call()
                } catch (e) {
                    console.log(e)
                    dispatch(setModalError({
                        title: i18next.t('error.wrongCustomCoinContract'),
                        text: i18next.t('error.wrongCustomCoinContractText'),
                        buttons: ['close'],
                    }))
                    return false
                }
            }
        }

        let transactions: ISendTransaction[] = []
        const hash = getDisplayHash(showcaseName)
        let items: IAssetItem[] = []
        let contracts: string[] = []
        for (let item of selectedTickets) {
            if (item.assetType !== _AssetType.ERC721) {
                continue
            }

            if (contracts.indexOf(item.contract) < 0) {
                contracts.push(item.contract)
                try {
                    const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, item.contract)
                    if (!(await contract.methods.isApprovedForAll(walletAddress, CHAINS[currentNetwork].showcaseV2Contract).call())) {
                        const method = contract.methods.setApprovalForAll(CHAINS[currentNetwork].showcaseV2Contract, true)
                        const encodedABI = method.encodeABI()
                        transactions.push({
                            trx: {
                                from: walletAddress,
                                to: item.contract,
                                data: encodedABI,
                            },
                            title: i18next.t('action.setApprovalForTickets'),
                        })
                    }
                    if (display.priceModel === CHAINS[currentNetwork].showcaseV2PriceModel.smart) {
                        if (!(await contract.methods.minters(display.priceModel).call())) {
                            const method = contract.methods.setMinterStatus(display.priceModel, true)
                            const encodedABI = method.encodeABI()
                            transactions.push({
                                trx: {
                                    from: walletAddress,
                                    to: item.contract,
                                    data: encodedABI,
                                },
                                title: i18next.t('action.setApprovalForMintTickets'),
                            })
                        }
                    }
                } catch (e) {
                    console.log(e)
                    continue
                }
            }

            items.push({
                asset: {assetType: item.assetType, contractAddress: item.contract},
                tokenId: item.tokenId,
                amount: 0
            })
        }

        if (items.length === 0) {
            dispatch(setModalError({
                title: i18next.t('error.wrongData'),
                text: i18next.t('error.ticketsNotSelectedOrWrongStandard'),
                buttons: ['close'],
            }))
            return false
        }

        const showcaseContract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        const method = showcaseContract.methods.addBatchItemsToDisplayWithSamePrice(hash, items, prices)
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].showcaseV2Contract,
                data: encodedABI,
            },
            title: i18next.t('action.addItemsToDisplay'),
            successfulSendingCallback: () => {
                dispatch(setTickets(null))
            }
        })
        dispatch(setModalSendTransactions({
            transactions,
            title: i18next.t('action.listingTickets', {name: showcaseName}),
            actionButton: {
                title: i18next.t('button.listingMore'),
                action: () => {
                    dispatch(setModalAddTicketsToShowcaseV2(false))
                    dispatch(setSelectedTickets([]))
                },
            },
            successButton: {
                title: i18next.t('button.showcase'),
                action: () => {
                    dispatch(setRedirectPath(`/showcases/${Number(currentNetwork)}/${showcaseName}`))
                }
            }
        }))
        return true
    }
)

export const buyAssetItem = createAsyncThunk(
    'showcaseV2/buyAssetItem',
    async (
        params: {
            eventLink: string
            item: IItemOnSale
            priceIndex: number
            showcaseName: string
            successText?: ReactNode
        },
        {dispatch, getState}
    ): Promise<boolean> => {
        const {eventLink, item, priceIndex, showcaseName, successText} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {discounts, showcase} = state.showcaseV2

        if (!currentNetwork || !web3 || !walletAddress || !showcase) {
            return false
        }

        let transactions: ISendTransaction[] = []

        let {payWith, amount} = item.prices[priceIndex]
        let d = discounts[`${item.nft.asset.contractAddress}-${item.nft.tokenId}`] || 0
        if (d > 0) {
            d = d > 10000 ? 10000 : d
            amount = amount.mul(10000 - d).div(10000)
        }
        amount = ethers.utils.parseUnits(amount.toString(), 'wei')
        if (payWith !== NULL_ADDRESS) {
            const contract = new web3.eth.Contract(Erc20Abi as AbiItem[], payWith)
            const balance = ethers.utils.parseUnits(await contract.methods.balanceOf(walletAddress).call(), 'wei')
            if (balance.gte(amount)) {
                const allowance = await contract.methods.allowance(walletAddress, CHAINS[currentNetwork].showcaseV2Contract).call()
                if (amount.gt(allowance)) {
                    const method = contract.methods.approve(CHAINS[currentNetwork].showcaseV2Contract, amount)
                    const encodedABI = method.encodeABI()
                    transactions.push({
                        trx: {
                            from: walletAddress,
                            to: payWith,
                            data: encodedABI,
                        },
                        title: i18next.t('action.setApproveForCoin'),
                    })
                }
            } else {
                dispatch(setModalError({
                    title: i18next.t('error.insufficientBalance'),
                    buttons: ['close'],
                }))
                return false
            }
        }

        let referrer = getFromStorage('referrer')
        if (referrer && !ethers.utils.isAddress(referrer)) {
            referrer = null
        }
        const showcaseContract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        const method = showcaseContract.methods.buyAssetItem(item.nft, priceIndex, walletAddress, referrer || NULL_ADDRESS, '')
        const encodedABI = method.encodeABI()
        let trx: ITransaction = {
            from: walletAddress,
            to: CHAINS[currentNetwork].showcaseV2Contract,
            data: encodedABI,
        }
        if (payWith === NULL_ADDRESS) {
            trx['value'] = amount
        }
        transactions.push({
            trx,
            title: i18next.t('action.buyItem'),
            successfulSendingCallback: () => {
                dispatch(requestShowcase({showcaseName, network: currentNetwork}))
            },
        })
        dispatch(setModalSendTransactions({
            transactions,
            actionButton: {
                action: () => {
                }, title: i18next.t('button.buyMore')
            },
            successButton: {
                action: () => {
                    dispatch(setRedirectPath(eventLink))
                }, title: i18next.t('button.goToEvent')
            },
            successText,
        }))
        return true
    }
)

export const cancelSale = createAsyncThunk(
    'showcaseV2/cancelSale',
    async (
        params: { item: IItemOnSale, priceIndex: number, showcaseName: string },
        {dispatch, getState}
    ): Promise<boolean> => {
        const {item, priceIndex, showcaseName} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const showcaseContract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        const method = showcaseContract.methods.buyAssetItem(item.nft, priceIndex, walletAddress, NULL_ADDRESS, '')
        const encodedABI = method.encodeABI()
        let trx: ITransaction = {
            from: walletAddress,
            to: CHAINS[currentNetwork].showcaseV2Contract,
            data: encodedABI,
        }
        transactions.push({
            trx,
            title: i18next.t('button.cancelSale'),
            successfulSendingCallback: () => {
                dispatch(requestShowcase({showcaseName, network: currentNetwork}))
            },
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const checkShowcaseName = createAsyncThunk(
    'showcaseV2/checkShowcaseName',
    async (
        params: {name: string, onSuccess: () => void, onFailure: () => void},
        {getState}
    ): Promise<void> => {
        const {name, onSuccess, onFailure} = params
        const state = getState() as RootState
        const {currentNetwork, web3} = state.app

        if (!currentNetwork || !web3 || name === '') {
            onFailure()
            return
        }

        const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        const showcase = await contract.methods.getDisplay(getDisplayHash(name)).call()
        if (showcase.owner === NULL_ADDRESS) {
            onSuccess()
        } else {
            onFailure()
        }
    }
)

export const editAssetItemPrice = createAsyncThunk(
    'showcaseV2/editAssetItemPrice',
    async (params: IEditAssetItemPriceParams, {dispatch, getState}): Promise<boolean> => {
        const {oldPrices, newPrices, assetItem} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        let editedCnt = 0
        let deleteCnt = oldPrices.length
        for (let i = 0; i < oldPrices.length; i++) {
            if (!newPrices[i]) {
                break
            }
            deleteCnt--
            editedCnt++

            if (oldPrices[i].payWith !== newPrices[i].payWith || !oldPrices[i].amount.eq(newPrices[i].amount)) {
                const method = contract.methods.editAssetItemPriceAtIndex(assetItem, i, newPrices[i])
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: CHAINS[currentNetwork].showcaseV2Contract,
                        data: encodedABI,
                    },
                    title: i18next.t('action.editItemPrice', {name: i + 1}),
                })
            }
        }
        for (let i = 0; i < deleteCnt; i++) {
            const method = contract.methods.removeLastPersonalPriceForAssetItem(assetItem)
            const encodedABI = method.encodeABI()
            transactions.push({
                trx: {
                    from: walletAddress,
                    to: CHAINS[currentNetwork].showcaseV2Contract,
                    data: encodedABI,
                },
                title: i18next.t('action.deleteLastPrice'),
            })
        }
        if (editedCnt < newPrices.length) {
            const method = contract.methods.addAssetItemPriceAtIndex(assetItem, newPrices.slice(editedCnt))
            const encodedABI = method.encodeABI()
            transactions.push({
                trx: {
                    from: walletAddress,
                    to: CHAINS[currentNetwork].showcaseV2Contract,
                    data: encodedABI,
                },
                title: i18next.t('action.addPrices'),
            })
        }
        if (transactions.length > 0) {
            transactions[transactions.length - 1].successfulSendingCallback = () => {
                dispatch(setShowcase(null))
            }
            dispatch(setModalSendTransactions({transactions}))
        }
        return true
    }
)

export const requestDiscount = createAsyncThunk(
    'showcaseV2/requestDiscount',
    async (
        {address, tokenId}: { address: string, tokenId: bigint },
        {getState}
    ): Promise<{ key: string, discount: number } | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        if (!ethers.utils.isAddress(address)) {
            console.log(`Wrong contract address: ${address}`)
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
            const item = [[3, address], tokenId.toString(), 0]
            const referrer = getFromStorage('referrer') || NULL_ADDRESS
            const discounts = await contract.methods.getAssetItemPricesAndDiscounts(item, walletAddress, referrer, '').call()
            let discount: number = 0
            for (let item of discounts[1]) {
                discount += Number(item.dsctPercent)
            }
            return {key: `${address}-${tokenId.toString()}`, discount}
        } catch (e) {
            console.log(e)
        }
        return null
    }
)

export const requestShowcase = createAsyncThunk(
    'showcaseV2/requestShowcase',
    async (params: { showcaseName: string, network?: string }, {getState}): Promise<IDisplay | null> => {
        const state = getState() as RootState
        let {currentNetwork, web3} = state.app
        const {showcaseName, network} = params

        if (currentNetwork && web3 && currentNetwork === network) {
            try {
                const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
                const hash = getDisplayHash(showcaseName)
                const result = await contract.methods.getDisplay(hash).call()
                const items: IItemOnSale[] = []
                for (let item of result.items) {
                    let prices: IPrice[] = []
                    for (let price of item.prices) {
                        prices.push({
                            amount: ethers.BigNumber.from(price.amount),
                            payWith: price.payWith.toLowerCase(),
                        })
                    }
                    items.push({
                        beneficiary: item.owner.toLowerCase(),
                        nft: {
                            amount: item.nft.amount,
                            asset: {...item.nft.asset, contractAddress: item.nft.asset.contractAddress.toLowerCase()},
                            tokenId: item.nft.tokenId,
                        },
                        owner: item.owner.toLowerCase(),
                        prices,
                    })
                }
                return {
                    beneficiary: result.beneficiary.toLowerCase(),
                    disableAfter: Number(result.disableAfter),
                    enableAfter: Number(result.enableAfter),
                    displayName: showcaseName,
                    items,
                    owner: result.owner.toLowerCase(),
                    priceModel: result.priceModel.toLowerCase(),
                }
            } catch (e) {
                console.log(e)
            }
        } else if (network) {
            try {
                const provider = ethers.getDefaultProvider(CHAINS[network].rpcUrl)
                const contract = new ethers.Contract(
                    CHAINS[network].showcaseV2Contract,
                    new ethers.utils.Interface(JSON.stringify(CHAINS[network].showcaseV2ContractAbi)),
                    provider
                )
                const hash = getDisplayHash(showcaseName)
                const result = await contract.getDisplay(hash)
                const items: IItemOnSale[] = []
                for (let item of result.items) {
                    let prices: IPrice[] = []
                    for (let price of item.prices) {
                        prices.push({
                            amount: price.amount,
                            payWith: price.payWith.toLowerCase(),
                        })
                    }
                    items.push({
                        beneficiary: item.owner.toLowerCase(),
                        nft: {
                            amount: item.nft.amount.toNumber(),
                            asset: {...item.nft.asset, contractAddress: item.nft.asset.contractAddress.toLowerCase()},
                            tokenId: item.nft.tokenId.toNumber(),
                        },
                        owner: item.owner.toLowerCase(),
                        prices,
                    })
                }
                return {
                    beneficiary: result.beneficiary.toLowerCase(),
                    disableAfter: result.disableAfter.toNumber(),
                    enableAfter: result.enableAfter.toNumber(),
                    displayName: showcaseName,
                    items,
                    owner: result.owner.toLowerCase(),
                    priceModel: result.priceModel.toLowerCase(),
                }
            } catch (e) {
                console.log(e)
            }
        }
        return null
    }
)

export const requestShowcaseEvents = createAsyncThunk(
    'showcaseV2/requestShowcaseEvents',
    async (params: { contract: string, displayName: string, network?: string }, {dispatch}): Promise<void> => {
        const {contract, displayName, network} = params

        let response: SliceResponse = {}
        if (!network || !CHAINS[network]) {
            response.error = {text: i18next.t('error.networkNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}showcases/${Number(network)}/${contract}/${getDisplayHash(displayName)}/events`)
                let events: DBEventWithOrganizer[] = []
                for (let event of result.data.events) {
                    events.push({
                        id: event.id,
                        title: event.title,
                        url: event.url,
                        description: event.description,
                        startTime: event.startTime,
                        endTime: event.endTime,
                        moderation: event.moderation,
                        organizer: event.organizer.toLowerCase(),
                        organizerId: Number(event.organizerId),
                        organizerTitle: event.organizerTitle,
                        organizerUrl: event.organizerUrl,
                        showcases: event.showcases.map((item: any): DBShowcase => ({
                            id: item.id,
                            chain: Number(item.chain_id),
                            contract: item.contract.toLowerCase(),
                            name: item.name,
                            eventId: Number(item.event_id),
                            title: item.title,
                        })),
                        types: event.types.map((item: any): DBCalendarEventType => ({
                            id: item.id,
                            title: item.title,
                        })),
                    })
                }
                response.status = result.status
                response.data = events
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setShowcaseEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestShowcases = createAsyncThunk(
    'showcaseV2/requestShowcases',
    async (_, {dispatch, getState}): Promise<IShowcase[]> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {addedShowcaseName} = state.showcaseV2

        if (!currentNetwork || !walletAddress || !web3) {
            return []
        }

        let showcases: IShowcase[] = []
        let showcaseFound = false
        try {
            const result = await axios.get(`${API_URL}oracle/kiosk/user/${Number(currentNetwork)}/${walletAddress}`)
            for (let item of result.data.showcases) {
                if (item.contract.toLowerCase() === CHAINS[currentNetwork].showcaseV2Contract) {
                    showcases.push({
                        blockNum: Number(item.blockNum),
                        contract: item.contract.toLowerCase(),
                        disableAfter: new Date(item.disableAfter),
                        enableAfter: new Date(item.enableAfter),
                        hash: item.hash,
                        id: Number(item.id),
                        logIndex: Number(item.logIndex),
                        name: item.name,
                        priceModel: item.priceModel.toLowerCase(),
                    })
                    if (item.name === addedShowcaseName) {
                        showcaseFound = true
                        dispatch(setAddedShowcaseName(null))
                    }
                }
            }
        } catch (e: any) {
            console.log('Error while loading user showcases')
            console.log(e)
        }
        if (!showcaseFound && addedShowcaseName) {
            try {
                const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
                const hash = getDisplayHash(addedShowcaseName)
                const display = await contract.methods.getDisplay(hash).call()
                if (display.owner !== NULL_ADDRESS) {
                    showcases.push({
                        blockNum: NaN,
                        contract: CHAINS[currentNetwork].showcaseV2Contract,
                        disableAfter: new Date(Number(display.disableAfter) * 1000),
                        enableAfter: new Date(Number(display.disableAfter) * 1000),
                        hash,
                        id: Number(hash),
                        logIndex: NaN,
                        name: addedShowcaseName,
                        priceModel: display.priceModel.toLowerCase(),
                    })
                }
            } catch (e) {
                console.log('Error while requesting user showcase from contract')
                console.log(e)
            }
        }
        return sortByBlockNum(showcases)
    }
)

export const setShowcaseParams = createAsyncThunk(
    'showcaseV2/setShowcaseParams',
    async (params: IShowcaseV2Params, {dispatch, getState}): Promise<boolean> => {
        const {beneficiary, disableAfter, showcaseName, edit, enableAfter, type} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const priceModel = currentNetwork ? CHAINS[currentNetwork].showcaseV2PriceModel[type] || '' : ''

        if (!currentNetwork || !web3 || !walletAddress || priceModel === '' || !ethers.utils.isAddress(beneficiary)) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].showcaseV2ContractAbi, CHAINS[currentNetwork].showcaseV2Contract)
        const method = contract.methods.setDisplayParams(
            showcaseName,
            beneficiary,
            enableAfter,
            disableAfter,
            priceModel,
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].showcaseV2Contract,
                data: encodedABI,
//                gasLimit: ethers.utils.parseUnits('0.01', 'gwei'),
            },
            title: edit ? i18next.t('button.editShowcase') : i18next.t('button.createShowcase'),
            afterSigningCallback: () => {
                dispatch(setAddedShowcaseName(showcaseName))
            },
            successfulSendingCallback: () => {
                if (edit) {
                    dispatch(requestShowcase({showcaseName: showcaseName, network: currentNetwork}))
                    dispatch(setModalEditShowcase(null))
                } else {
                    dispatch(requestShowcases())
                    dispatch(setModalCreateShowcaseV2(null))
                    setDisplayId(Number(getDisplayHash(showcaseName)))
                }
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const showcaseV2Slice = createSlice({
    name: 'showcaseV2',
    initialState,
    reducers: {
        selectedTicketHandler: (state, action: PayloadAction<ICheckedTicket>) => {
            let newState: ICheckedTicket[] = []
            let found = false
            for (let item of state.selectedTickets) {
                if (item.contract === action.payload.contract && item.tokenId === action.payload.tokenId) {
                    found = true
                    continue
                }
                newState.push(item)
            }
            if (!found) {
                newState.push(action.payload)
            }
            state.selectedTickets = newState
        },
        setAddedShowcaseName: (state, action: PayloadAction<string | null>) => {
            state.addedShowcaseName = action.payload
        },
        setDiscounts: (state, action: PayloadAction<IDiscounts>) => {
            state.discounts = action.payload
        },
        setSelectedTickets: (state, action: PayloadAction<ICheckedTicket[]>) => {
            state.selectedTickets = action.payload
        },
        setShowcase: (state, action: PayloadAction<IDisplay | null>) => {
            state.showcase = action.payload
        },
        setShowcaseEvents: (state, action: PayloadAction<DBEventWithOrganizer[] | null>) => {
            state.showcaseEvents = action.payload
        },
        setShowcaseName: (state, action: PayloadAction<string | null>) => {
            state.showcaseName = action.payload
        },
        setShowcases: (state, action: PayloadAction<IShowcase[] | null>) => {
            state.showcases = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestDiscount.fulfilled, (state, action: PayloadAction<{
            key: string,
            discount: number
        } | null>) => {
            if (action.payload) {
                state.discounts[action.payload.key] = action.payload.discount
            }
        })
        builder.addCase(requestShowcases.fulfilled, (state, action: PayloadAction<IShowcase[] | null>) => {
            state.showcases = action.payload
        })
        builder.addCase(requestShowcase.fulfilled, (state, action: PayloadAction<IDisplay | null>) => {
            state.showcase = action.payload
        })
    },
})

export const getAddedShowcaseName = (state: RootState): string | null => state.showcaseV2.addedShowcaseName
export const getDiscounts = (state: RootState): IDiscounts => state.showcaseV2.discounts
export const getDiscount = (key: string) => (state: RootState): number => state.showcaseV2.discounts[key] || 0
//todo: move to storage.ts
export const getDisplay = (state: RootState): IShowcase | null => {
    const id = getDisplayId()
    if (id === null || !state.showcaseV2.showcases) {
        return null
    }

    for (let item of state.showcaseV2.showcases) {
        if (item.id === id) {
            return item
        }
    }
    setDisplayId(null)
    return null
}
export const getDisplayId = (): number | null => {
    const id = localStorage.getItem('displayId')
    return id === '' ? null : Number(id)
}
export const getShowcaseName = (state: RootState): string | null => state.showcaseV2.showcaseName
export const getShowcases = (state: RootState): IShowcase[] | null => state.showcaseV2.showcases
export const getShowcasesByType = (type: TShowcaseType | null) => (state: RootState): IShowcase[] | null => {
    let currentNetwork = state.app.currentNetwork
    if (!type || !currentNetwork) {
        return null
    }

    let showcases: IShowcase[] = []
    for (let showcase of state.showcaseV2.showcases || []) {
        if (showcase.priceModel === CHAINS[currentNetwork]?.showcaseV2PriceModel[type]) {
            showcases.push(showcase)
        }
    }
    return showcases
}
export const getSelectedTickets = (state: RootState): ICheckedTicket[] => state.showcaseV2.selectedTickets
export const getShowcase = (state: RootState): IDisplay | null => state.showcaseV2.showcase
export const getShowcaseEvents = (state: RootState): DBEventWithOrganizer[] | null => state.showcaseV2.showcaseEvents
//todo: move to storage.ts
export const setDisplayId = (id: number | null): void => {
    localStorage.setItem('displayId', id?.toString() || '')
}

export const {
    selectedTicketHandler,
    setAddedShowcaseName,
    setDiscounts,
    setShowcaseName,
    setShowcases,
    setSelectedTickets,
    setShowcase,
    setShowcaseEvents,
} = showcaseV2Slice.actions

export default showcaseV2Slice.reducer
