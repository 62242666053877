import React, {useContext, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getCurrentNetwork, getModalMintTickets, setModalMintTickets, setRedirectPath} from '../../store/appSlice'
import {
    getMintedTokenId,
    getNftJsonUrl,
    getNftTxId,
    getNftTxSigned,
    getWhitelistedTickets,
    resetMintState, setMintedTokenId
} from '../../store/mintSlice'
import {CHAINS} from '../../utils/constants'
import {ExternalLinkIcon, SuccessIcon} from '../icons'
import {ButtonElement} from '../elements'
import {ThemeContext} from '../../context'

interface propsType {
    show: boolean
}

const ModalMintTickets = (props: propsType) => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const modalFade = useRef(null)
    const currentNetwork = useSelector(getCurrentNetwork)
    const mintedTokenId = useSelector(getMintedTokenId)
    const modal = useSelector(getModalMintTickets)
    const nftJsonUrl = useSelector(getNftJsonUrl)
    const nftTxId = useSelector(getNftTxId)
    const nftTxSigned = useSelector(getNftTxSigned)
    const whitelistedTickets = useSelector(getWhitelistedTickets)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setMintedTokenId(null))
    }, [])
    useEffect(() => {
        if (whitelistedTickets) {
            const timer = setTimeout(redirectHandler, 15000)
            return () => {
                clearTimeout(timer)
            }
        }
    }, [modal, whitelistedTickets])

    if (!currentNetwork || !modal) {
        return null
    }

    const closeModal = () => {
        dispatch(setModalMintTickets(false))
        dispatch(resetMintState())
    }
    const redirectHandler = () => {
        closeModal()
        dispatch(setRedirectPath(`/${showcaseType}/showcases`))
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Progress steps"
        aria-hidden="true"
        style={{display: 'block'}}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.mintTickets')}</h2>
                </div>
                <div className="modal-body">
                    <div>
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col`}>
                                    {!nftJsonUrl ? t('status.metadataSaving') : t('status.metadataSaved')}
                                </div>
                                <div className="col-auto">
                                    {nftJsonUrl ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="visually-hidden">{t('status.loading')}...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col ${!nftJsonUrl ? 'text-muted' : ''}`}>
                                    {!nftTxSigned ?
                                        t('status.signingMintNft')
                                        :
                                        nftTxId ? t('status.mintedNft', {name: (mintedTokenId || []).join(', ')}) : t('status.mintingNft')
                                    }
                                </div>
                                <div className="col-auto">
                                    {nftTxId ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        nftJsonUrl ?
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">{t('status.loading')}...</span>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        {showcaseType !== 'onchain' ?
                            <div className="progress-step">
                                <div className="row align-items-center">
                                    <div className={`col ${!nftTxId ? 'text-muted' : ''}`}>
                                        {!whitelistedTickets ?
                                            t('status.addingTicketsToWhitelist')
                                            :
                                            t('status.addedTicketsToWhitelist', {
                                                value1: whitelistedTickets.added,
                                                value2: whitelistedTickets.errors
                                            })
                                        }
                                    </div>
                                    <div className="col-auto">
                                        {whitelistedTickets ?
                                            <SuccessIcon className={'icon-success'}/>
                                            :
                                            nftTxId ?
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">{t('status.loading')}...</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="col-12 col-lg mb-3 mb-lg-0">
                        {nftTxId ?
                            <a
                                className="icon-link"
                                href={`${CHAINS[currentNetwork].blockExplorer}/tx/${nftTxId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{t('word.transaction')}</span>
                                <ExternalLinkIcon/>
                            </a>
                            :
                            null
                        }
                    </div>
                    <div className="col-12 col-lg-auto">
                        <div className="row gx-3">
                            {whitelistedTickets ?
                                <>
                                    <div className="col-sm-6 col-lg-auto order-md-2 mb-3 mb-lg-0">
                                        <ButtonElement
                                            className={'w-100'}
                                            onClick={redirectHandler}
                                        >{t('button.continue')}</ButtonElement>
                                    </div>
                                    <div className="col-sm-6 col-lg-auto order-md-1 mb-3 mb-lg-0">
                                        <ButtonElement
                                            outline
                                            type={'secondary'}
                                            className={'w-100'}
                                            onClick={closeModal}
                                        >{t('button.mintTickets')}</ButtonElement>
                                    </div>
                                </>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalMintTickets
