import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getTickets, requestTokens, resetTickets, setTickets} from '../../store/ticketsSlice'
import {AppDispatch} from '../../store/store'
import {SelectEvent, SelectOrganizer, SelectTicketLevel} from '../profile'
import {getEventsObject} from '../../store/eventsSlice'
import {TicketItem} from './index'
import {
    ButtonElement,
    CheckboxElement,
    DateFilter,
    DropdownSelector,
    InputElement,
    NoteElement
} from '../elements'
import {getCurrentNetwork, getTokenSmartcontracts, getWalletAddress} from '../../store/appSlice'
import {getCollections, requestMintCollections} from '../../store/mintSlice'
import {FiltersIcon} from '../icons'
import {SHOWCASE_TOKENS_ON_PAGE} from '../../utils/constants'
import {getTokens} from '../../store/tokensSlice'
import {IDropdownItem} from '../../store/types'
import {getFromStorage, setToStorage} from '../../store/storage'
import {getDateFilter, getDateFilterAsc} from '../../store/inputSlice'

const Tickets = () => {
    const {t, i18n} = useTranslation()
    const [addressFilter, setAddressFilter] = useState('')
    const [checkedSmartcontracts, setCheckedSmartcontracts] = useState(true)
    const [eventsFilter, setEventsFilter] = useState<IDropdownItem[]>([])
    const [hideNote, setHideNote] = useState(getFromStorage('hideTicketsNote'))
    const [levelsFilter, setLevelsFilter] = useState<IDropdownItem[]>([])
    const [nextPage, setNextPage] = useState(1)
    const [selectedEventFilter, setSelectedEventFilter] = useState(0)
    const [selectedLevelFilter, setSelectedLevelFilter] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const [tokenIsTicket, setTokenIsTicket] = useState(true)
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const dateFilter = useSelector(getDateFilter)
    const dateFilterAsc = useSelector(getDateFilterAsc)
    const events = useSelector(getEventsObject)
    const tickets = useSelector(getTickets)
    const tokens = useSelector(getTokens)
    const tokenSmartcontracts = useSelector(getTokenSmartcontracts)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(requestTokens())
        }, 30000)
        return () => {
            clearInterval(timer)
            dispatch(resetTickets())
        }
    }, [])
    useEffect(() => {
        if (tickets) {
            dispatch(setTickets(null))
        } else if (walletAddress) {
            dispatch(requestTokens())
        }
        if (currentNetwork && walletAddress) {
            dispatch(requestMintCollections())
        }
    }, [currentNetwork, walletAddress])
    useEffect(() => {
        if (!tickets && walletAddress) {
            dispatch(requestTokens())
        }
        let eventsList: IDropdownItem[] = [{id: 0, name: t('form.label.allEvents')}]
        let levelsList: IDropdownItem[] = [{id: '', name: t('form.label.allLevels')}]
        let ids: number[] = []
        let ids2: string[] = []
        for (let item of tickets || []) {
            if (item.eventId && events && events[item.eventId] && ids.indexOf(item.eventId) < 0) {
                eventsList.push({id: item.eventId, name: events[item.eventId].title})
                ids.push(item.eventId)
            }
            if (item.level && ids2.indexOf(item.level.title) < 0) {
                levelsList.push({id: item.level.title, name: item.level.title})
                ids2.push(item.level.title)
            }
        }
        setEventsFilter(eventsList)
        setLevelsFilter(levelsList)
    }, [tickets, events, i18n.language])

    const ticketsList = useMemo(() => {
        if (!tickets || !currentNetwork) {
            return <div>{t('status.loadingTicketsDescription')}</div>
        }

        if (tickets.length === 0) {
            return <div>{t('status.emptyList')}</div>
        }

        let filtered = [...tickets].sort((itemA, itemB) => {
            return (itemA.blockNum - itemB.blockNum) * (dateFilterAsc ? 1 : -1)
        })
        filtered = filtered.filter((item) => {
            return !tokenIsTicket || item.isTicket
        }).filter((item) => {
            return item.tokenId.toString().indexOf(addressFilter) >= 0 || item.contract.indexOf(addressFilter.toLowerCase()) >= 0
        }).filter((item) => {
            return selectedEventFilter === 0 || item.eventId === selectedEventFilter
        }).filter((item) => {
            return selectedLevelFilter === '' || (item.level && item.level.title === selectedLevelFilter)
        }).filter((item) => {
            return !checkedSmartcontracts || tokenSmartcontracts.indexOf(item.contract) >= 0
        }).filter((item) => {
            if (!dateFilter) {
                return true
            }

            const date = tokens?.[`${item.contract}-${item.tokenId}`]?.date
            if (!date) {
                return false
            }

            const delta = date.getTime() - dateFilter.getTime()
            return delta >= 0 && delta < 86400000
        })

        if (filtered.length === 0) {
            return <div>{t('status.noNftFitsFilter')}</div>
        } else {
            let list: ReactNode[] = filtered.slice(0, SHOWCASE_TOKENS_ON_PAGE * nextPage).map((item) => (
                <TicketItem key={`${item.contract}-${item.tokenId}`} item={item}/>
            ))
            if (filtered.length > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
                list.push(<ButtonElement
                    key={'loadMore'}
                    outline
                    onClick={() => {
                        setNextPage(nextPage + 1)
                    }}
                >{t('button.loadMore')}</ButtonElement>)
            }
            return list
        }
    }, [tickets, addressFilter, checkedSmartcontracts, nextPage, selectedEventFilter, selectedLevelFilter, tokenIsTicket, tokenSmartcontracts])

    const addressFilterHandler = (value: string) => {
        if (value === '' || /^[a-zA-Z0-9]+$/.test(value)) {
            setAddressFilter(value)
        }
    }
    const hideHandler = () => {
        setHideNote('hide')
        setToStorage('hideTicketsNote', 'hide')
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t('header.myNfts')}</h1>
            <div className="mb-3">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectOrganizer/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectEvent/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mb-4">
                            <SelectTicketLevel/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row gx-3 gx-xl-4 mb-3 mb-xl-2">
                <div
                    className="col-12 col-md-4 col-lg-auto mb-3 order-1 order-md-0 col-filter__mob-toggled"
                    style={showFilters ? {display: 'block'} : {}}
                >
                    <DateFilter/>
                </div>
                <div
                    className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                    style={showFilters ? {display: 'block'} : {}}
                >
                    <DropdownSelector
                        list={eventsFilter}
                        setItem={setSelectedEventFilter}
                        currentItem={selectedEventFilter}
                        button
                    />
                </div>
                <div
                    className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                    style={showFilters ? {display: 'block'} : {}}
                >
                    <DropdownSelector
                        list={levelsFilter}
                        setItem={setSelectedLevelFilter}
                        currentItem={selectedLevelFilter}
                        button
                    />
                </div>
                <div className="col-8 col-md-4 col-lg-3 col-xl-2 mb-4 mb-lg-0 order-0">
                    <InputElement
                        value={addressFilter}
                        onChange={addressFilterHandler}
                        additionalClass={'form-control form-control-sm control-search'}
                        placeholder={t('form.label.addressOrId')}
                    />
                </div>
                <div className="col-4 mb-4 order-0 d-md-none">
                    <ButtonElement
                        small
                        outline
                        className={`btn-filter-toggle w-100 ${showFilters ? 'show' : ''}`}
                        onClick={() => {
                            setShowFilters(!showFilters)
                        }}
                    >
                        <FiltersIcon className="me-2"/>
                        <span>{t('button.filters')}</span>
                    </ButtonElement>
                </div>
                <div
                    className="col-md-auto col-lg-auto order-1 col-filter__mob-toggled"
                    style={showFilters ? {display: 'block'} : {}}
                >
                    <CheckboxElement
                        checked={checkedSmartcontracts}
                        onChange={() => {
                            setCheckedSmartcontracts(!checkedSmartcontracts)
                        }}
                        label={t('form.label.myshchSmartContracts')}
                        loading={collections === null}
                    />
                </div>
                <div
                    className="col-md-auto col-lg-auto order-1 col-filter__mob-toggled"
                    style={showFilters ? {display: 'block'} : {}}
                >
                    <CheckboxElement
                        checked={tokenIsTicket}
                        onChange={() => {
                            setTokenIsTicket(!tokenIsTicket)
                        }}
                        label={t('ticket.type.isTicket')}
                    />
                </div>
            </div>
            {!hideNote ? <NoteElement crossAction={hideHandler}>{t('ticket.checkboxWarning')}</NoteElement> : null}
            <div>
                <div className="row-table">
                    {ticketsList}
                </div>
            </div>
        </div>
    </div>
}

export default Tickets
